import './App.css';

function App() {
  return (
    <div className="App">
        <img src={process.env.PUBLIC_URL + '/santa_tree.gif'} className="App-santa-tree" alt="Ho Ho Ho" />
    </div>
  );
}

export default App;
